import React from 'react';
import { Box, Flex, Text, HStack, Badge, Stat, StatLabel, StatNumber, VStack, Link, SimpleGrid, useColorModeValue, useTheme, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Alert } from '../types/alert';
import { ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';
import { FiExternalLink } from 'react-icons/fi';
import { Timestamp } from 'firebase/firestore';

interface CompactAlertCardProps {
  alert: Alert;
  onClick: () => void;
  isExpandable: boolean;
  isExpanded?: boolean;  // Make this optional
  isActive?: boolean;
}

const formatNumber = (num: number) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
};

const formatPrice = (price: number) => {
  return price.toFixed(2);
};

const formatTimestamp = (timestamp: Alert['timestamp']) => {
  let date: Date;
  if (timestamp instanceof Date) {
    date = timestamp;
  } else if (timestamp instanceof Timestamp) {
    date = timestamp.toDate();
  } else if (typeof timestamp === 'object' && 'seconds' in timestamp) {
    date = new Date(timestamp.seconds * 1000);
  } else if (typeof timestamp === 'string') {
    date = new Date(timestamp);
  } else {
    // fallback to current date if timestamp is invalid
    date = new Date();
    console.error('Invalid timestamp format:', timestamp);
  }
  return format(date, "MMM d | h:mma").toLowerCase();
};

const formatLargeNumber = (num: number) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + ' Billion';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + ' Million';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + ' Thousand';
  } else {
    return num.toString();
  }
};

const CompactChart: React.FC<{ data: any[] }> = ({ data }) => {
  const accentColor = '#1a88e4';
  
  const chartTheme = useColorModeValue(
    { 
      areaColor: accentColor,
      strokeColor: accentColor,
    },
    { 
      areaColor: accentColor,
      strokeColor: accentColor,
    }
  );

  const tooltipStyle = useColorModeValue(
    { 
      backgroundColor: 'white', 
      border: '1px solid #E2E8F0', 
      color: 'gray.800',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    { 
      backgroundColor: 'black', 
      border: '1px solid #4A5568', 
      color: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,0.3)'
    }
  );

  return (
    <Box height="100%" width="100%">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={chartTheme.areaColor} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={chartTheme.areaColor} stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Tooltip
            labelFormatter={(value) => format(new Date(value), 'MMM dd, yyyy')}
            formatter={(value: number) => [`$${value.toFixed(2)}`, 'Price']}
            contentStyle={{
              ...tooltipStyle,
              fontSize: '12px',
              padding: '8px',
              borderRadius: '4px',
            }}
            itemStyle={{ padding: '0' }}
            labelStyle={{ marginBottom: '4px' }}
          />
          <Area 
            type="monotone" 
            dataKey="close" 
            stroke={chartTheme.strokeColor} 
            strokeWidth={2} 
            fillOpacity={1} 
            fill="url(#colorClose)" 
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export const CompactAlertCard: React.FC<CompactAlertCardProps> = ({ 
  alert, 
  isExpandable = false, 
  isExpanded = false, 
  isActive = false,
  onClick 
}) => {
  const cardBg = useColorModeValue('white', 'whiteAlpha.50');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBorderColor = useColorModeValue('blue.500', 'blue.300');
  const textColor = useColorModeValue('gray.800', 'white');
  const subtleTextColor = useColorModeValue('gray.500', 'gray.400');
  const linkColor = useColorModeValue('blue.600', 'blue.300');
  const headlineBg = useColorModeValue('gray.100', 'gray.700');

  const intervalBadgeBg = useColorModeValue('blue.100', 'blue.900');
  const intervalBadgeColor = useColorModeValue('blue.800', 'blue.100');
  const multiplierBadgeBg = useColorModeValue('green.100', 'green.900');
  const multiplierBadgeColor = useColorModeValue('green.800', 'green.100');

  const expandedBg = useColorModeValue('gray.50', 'whiteAlpha.25');

  const borderRadius = "20px";

  const handleTabClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const formattedDateTime = formatTimestamp(alert.timestamp);

  return (
    <Box 
      bg={cardBg} 
      borderRadius={borderRadius}
      boxShadow="sm" 
      borderWidth="1px" 
      borderColor={borderColor}
      onClick={onClick}
      cursor="pointer"
      transition="all 0.2s"
      _hover={{
        transform: 'translateY(-2px)',
        boxShadow: 'md',
        borderColor: hoverBorderColor,
      }}
      overflow="hidden"
    >
      <Box p={3} borderBottomWidth={isExpandable && isExpanded ? "1px" : "0"} borderColor={borderColor}>
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <HStack spacing={2}>
            <Text fontSize="md" fontWeight="bold" color={textColor}>{alert.stockTicker}</Text>
            <Badge bg={intervalBadgeBg} color={intervalBadgeColor} px={2} py={1} borderRadius="full">
              {alert.interval}
            </Badge>
            <Badge bg={multiplierBadgeBg} color={multiplierBadgeColor} px={2} py={1} borderRadius="full">
              {alert.volumeMultiple.toFixed(2)}x
            </Badge>
          </HStack>
          <HStack spacing={2}>
            <Badge 
              colorScheme={
                alert.grade === 'A+' ? 'green' : 
                alert.grade === 'A' ? 'teal' : 
                alert.grade === 'B+' ? 'blue' : 
                alert.grade === 'B' ? 'cyan' : 
                'purple'
              }
              px={2} py={1} borderRadius="full"
            >
              {alert.grade}
            </Badge>
            <Text fontSize="xs" color={subtleTextColor}>
              {formattedDateTime}
            </Text>
          </HStack>
        </Flex>
      </Box>

      {isExpandable && isExpanded && (
        <Box onClick={(e) => e.stopPropagation()}>
          <SimpleGrid columns={2} spacing={4} p={4} bg={expandedBg}>
            <Stat size="sm">
              <StatLabel fontSize="xs">Price at Alert</StatLabel>
              <StatNumber fontSize="md">${formatPrice(alert.priceAtAlert)}</StatNumber>
            </Stat>
            <Stat size="sm">
              <StatLabel fontSize="xs">Current Volume</StatLabel>
              <StatNumber fontSize="md">{formatNumber(alert.currentDailyVolume)}</StatNumber>
            </Stat>
            <Stat size="sm">
              <StatLabel fontSize="xs">Market Cap</StatLabel>
              <StatNumber fontSize="md">{formatLargeNumber(alert.companyInfo?.market_cap || 0)}</StatNumber>
            </Stat>
            <Stat size="sm">
              <StatLabel fontSize="xs">Employees</StatLabel>
              <StatNumber fontSize="md">{formatLargeNumber(alert.companyInfo?.total_employees || 0)}</StatNumber>
            </Stat>
          </SimpleGrid>

          <Tabs isFitted colorScheme="blue" variant="enclosed" onClick={handleTabClick}>
            <TabList>
              <Tab fontWeight="semibold">Chart</Tab>
              <Tab fontWeight="semibold">Company Info</Tab>
              <Tab fontWeight="semibold">News</Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                {alert.candlestickData && alert.candlestickData.length > 0 && (
                  <Box height="200px">
                    <CompactChart data={alert.candlestickData} />
                  </Box>
                )}
              </TabPanel>
              <TabPanel p={4}>
                <VStack align="stretch" spacing={3}>
                  <Text fontSize="sm" color={subtleTextColor}>{alert.companyInfo?.description}</Text>
                  <SimpleGrid columns={2} spacing={3}>
                    <Box>
                      <Text fontSize="xs" color={subtleTextColor}>Industry:</Text>
                      <Text fontSize="sm" color={textColor}>{alert.companyInfo?.industry || 'N/A'}</Text>
                    </Box>
                    <Box>
                      <Text fontSize="xs" color={subtleTextColor}>Exchange:</Text>
                      <Text fontSize="sm" color={textColor}>{alert.companyInfo?.primary_exchange || 'N/A'}</Text>
                    </Box>
                  </SimpleGrid>
                  {alert.companyInfo?.homepage_url && (
                    <Link href={alert.companyInfo.homepage_url} isExternal color={linkColor} fontSize="sm">
                      Company Website <FiExternalLink style={{ display: 'inline', marginLeft: '4px' }} />
                    </Link>
                  )}
                </VStack>
              </TabPanel>
              <TabPanel p={4}>
                {alert.recentNews && alert.recentNews.length > 0 ? (
                  <VStack align="stretch" spacing={3}>
                    {alert.recentNews.slice(0, 2).map((news, index) => (
                      <Box key={index} p={3} borderRadius={borderRadius} bg={headlineBg}>
                        <Link href={news.url} isExternal color={linkColor} fontSize="sm">
                          {news.title}
                        </Link>
                      </Box>
                    ))}
                  </VStack>
                ) : (
                  <Text fontSize="sm" color={subtleTextColor}>No recent news available.</Text>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Box>
  );
};
