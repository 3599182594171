import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Box, Flex, VStack, Text, Button, Input, IconButton, Spinner,
  Container, Popover, PopoverTrigger, PopoverContent, PopoverBody,
  Grid, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
  Tag, TagLabel, TagCloseButton, Badge, Tooltip, useColorModeValue, Alert, AlertIcon
} from '@chakra-ui/react';
import { FiCalendar, FiChevronDown, FiChevronLeft, FiChevronRight, FiFilter, FiTrash2 } from 'react-icons/fi';
import { format, subMonths, startOfMonth, endOfMonth, isSameMonth, isToday, isSameDay, addDays, startOfWeek, endOfWeek, startOfDay, endOfDay } from 'date-fns';
import { collection, query, getDocs, where, Timestamp, orderBy, limit, startAfter, getCountFromServer } from 'firebase/firestore';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import Navbar from './Navbar';
import { db } from '../config/firebase';
import { Alert as AlertType } from '../types/alert';  // Renamed to AlertType
import { useAlertsContext } from '../contexts/AlertsContext';
import { CompactAlertCard } from './CompactAlertCard';
import { useGlobalFilters } from '../contexts/GlobalFiltersContext';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';

const ALERTS_PER_PAGE = 20;

const Alerts: React.FC = () => {
  const { 
    dateRange, setDateRange, 
    filterTicker, setFilterTicker, 
    filterInterval, setFilterInterval, 
    filterMultiplier, setFilterMultiplier,
    filterMarketCap, setFilterMarketCap,
    filterIndustry, setFilterIndustry
  } = useAlertsContext();
  const [ref, inView] = useInView();
  const [expandedAlertId, setExpandedAlertId] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { globalFilters } = useGlobalFilters();

  const accentColor = '#1a88e4';
  const bgColor = useColorModeValue("gray.50", "black");
  const componentBgColor = useColorModeValue("white", "black");
  const borderColor = useColorModeValue("gray.200", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.300");
  const headingColor = useColorModeValue("gray.900", "gray.100");
  const subtleTextColor = useColorModeValue("gray.600", "gray.500");

  const calendarStyles = {
    '.calendar-container': {
      width: '280px',
      height: '320px',
    },
    '.calendar-grid': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gap: '1px',
      height: '240px',
    },
    '.calendar-day': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      aspectRatio: '1 / 1',
      cursor: 'pointer',
      borderRadius: 'md',
      transition: 'all 0.2s',
      fontSize: '0.8rem',
      _hover: { bg: borderColor },
    },
    '.calendar-day-selected': {
      bg: accentColor,
      color: 'white',
      fontWeight: 'bold',
      _hover: { bg: `${accentColor}CC` },
    },
    '.calendar-day-range': {
      color: textColor,
    },
    '.calendar-day-range-start, .calendar-day-range-end': {
      bg: accentColor,
      color: 'white',
    },
    '.calendar-day-today': {
      fontWeight: 'bold',
      color: 'white',
      bg: accentColor,
    },
    '.calendar-day-disabled': {
      opacity: 0.4,
      cursor: 'default',
      _hover: { bg: 'transparent' },
      color: 'gray.400',
    },
  };

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handlePrevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const isCurrentMonthToday = isSameMonth(currentMonth, new Date());

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const renderCalendar = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const days = [];

    let day = startDate;
    while (day <= endDate) {
      for (let i = 0; i < 42; i++) {
        const formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isCurrentMonth = isSameMonth(day, currentMonth);
        const isSelected = isCurrentMonth && selectedDate && isSameDay(cloneDay, selectedDate);
        const isToday = isCurrentMonth && isSameDay(day, new Date());

        days.push(
          <Box
            key={day.toString()}
            onClick={() => isCurrentMonth && handleDateClick(cloneDay)}
            className={`calendar-day ${
              isCurrentMonth
                ? isSelected
                  ? 'calendar-day-selected'
                  : isToday
                    ? 'calendar-day-today'
                    : ''
                : 'calendar-day-disabled'
            }`}
          >
            {formattedDate}
          </Box>
        );
        day = addDays(day, 1);
      }
    }
    return <Box className="calendar-grid">{days}</Box>;
  };

  const queryClient = useQueryClient();
  const [cachedAlerts, setCachedAlerts] = useLocalStorage<AlertType[]>('cachedAlerts', []);

  const [localFilters, setLocalFilters] = useState({
    interval: '',
    multiplier: '',
    marketCap: '',
    industry: '',
  });

  const [error, setError] = useState<string | null>(null);

  const fetchAlerts = useCallback(async ({ pageParam = null }) => {
    try {
      const alertsRef = collection(db, 'alerts');
      let q = query(alertsRef, orderBy('timestamp', 'desc'), limit(ALERTS_PER_PAGE));

      if (pageParam) {
        q = query(q, startAfter(pageParam));
      }

      const querySnapshot = await getDocs(q);
      const fetchedAlerts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as AlertType));
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      // Update cached alerts
      setCachedAlerts((prevCachedAlerts: AlertType[]) => [...prevCachedAlerts, ...fetchedAlerts]);

      return { alerts: fetchedAlerts, lastVisible };
    } catch (error) {
      console.error("Error fetching alerts:", error);
      setError("Failed to load alerts. Please try again later.");
      return { alerts: [], lastVisible: null };
    }
  }, [setCachedAlerts]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['alerts', dateRange, filterTicker, localFilters],
    fetchAlerts,
    {
      getNextPageParam: (lastPage) => lastPage.lastVisible || undefined,
      initialData: () => {
        return {
          pages: [{ alerts: cachedAlerts, lastVisible: null as unknown as QueryDocumentSnapshot<DocumentData, DocumentData> }],
          pageParams: [null],
        };
      },
    }
  );

  React.useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const allAlerts = useMemo(() => 
    data?.pages.flatMap(page => page.alerts) || [],
    [data]
  );

  const checkMarketCapMatch = (marketCap: number | undefined, filter: string) => {
    if (marketCap === undefined) return false;
    switch(filter) {
      case 'nano': return marketCap < 50000000;
      case 'micro': return marketCap >= 50000000 && marketCap < 300000000;
      case 'small': return marketCap >= 300000000 && marketCap < 2000000000;
      case 'mid': return marketCap >= 2000000000 && marketCap < 10000000000;
      case 'large': return marketCap >= 10000000000 && marketCap < 200000000000;
      case 'mega': return marketCap >= 200000000000;
      default: return true;
    }
  };

  const filteredAlerts = useMemo(() => 
    allAlerts.filter(alert => {
      const matchesTicker = !filterTicker || alert.stockTicker.toLowerCase().includes(filterTicker.toLowerCase());

      const matchesInterval = !localFilters.interval || alert.interval === localFilters.interval;

      const matchesMultiplier = !localFilters.multiplier || 
        alert.volumeMultiple >= parseFloat(localFilters.multiplier);

      const matchesMarketCap = !localFilters.marketCap || 
        checkMarketCapMatch(alert.companyInfo?.market_cap, localFilters.marketCap);

      const matchesIndustry = !localFilters.industry || 
        alert.companyInfo?.industry === localFilters.industry;

      const matchesDateRange = !dateRange || (() => {
        let timestamp: Date;
        if (alert.timestamp instanceof Date) {
          timestamp = alert.timestamp;
        } else if (typeof alert.timestamp === 'object' && 'toDate' in alert.timestamp) {
          timestamp = alert.timestamp.toDate();
        } else if (typeof alert.timestamp === 'string') {
          timestamp = new Date(alert.timestamp);
        } else {
          // If we can't parse the timestamp, we'll exclude this alert
          return false;
        }

        const start = startOfDay(dateRange.from);
        const end = dateRange.to ? endOfDay(dateRange.to) : endOfDay(dateRange.from);
        return timestamp >= start && timestamp <= end;
      })();

      return matchesTicker && matchesInterval && matchesMultiplier && 
             matchesMarketCap && matchesIndustry && matchesDateRange;
    }),
    [allAlerts, filterTicker, localFilters, dateRange]
  );

  const [isApplyingFilters, setIsApplyingFilters] = useState(false);

  const handleApplyDateRange = () => {
    if (selectedDate) {
      setDateRange({ from: selectedDate, to: selectedDate });
    } else {
      setDateRange(undefined);
    }
  };

  const handleApplyFilters = () => {
    setIsApplyingFilters(true);
    handleApplyDateRange();
    setLocalFilters({
      interval: filterInterval,
      multiplier: filterMultiplier,
      marketCap: filterMarketCap,
      industry: filterIndustry,
    });
    onClose();
    setTimeout(() => setIsApplyingFilters(false), 500);
  };

  const clearAllFilters = () => {
    setFilterTicker('');
    setLocalFilters({
      interval: '',
      multiplier: '',
      marketCap: '',
      industry: '',
    });
    setDateRange(undefined);
    setSelectedDate(null);
  };

  const renderFilterTags = () => {
    const tags = [];

    if (filterTicker) {
      tags.push(
        <Tag key="ticker" size="md" borderRadius="full" variant="solid" colorScheme="blue" mr={2} mb={2}>
          <TagLabel>Ticker: {filterTicker}</TagLabel>
          <TagCloseButton onClick={() => setFilterTicker('')} />
        </Tag>
      );
    }

    if (localFilters.interval) {
      tags.push(
        <Tag key="interval" size="md" borderRadius="full" variant="solid" colorScheme="green" mr={2} mb={2}>
          <TagLabel>Interval: {localFilters.interval}</TagLabel>
          <TagCloseButton onClick={() => setLocalFilters(prev => ({ ...prev, interval: '' }))} />
        </Tag>
      );
    }

    if (localFilters.multiplier) {
      tags.push(
        <Tag key="multiplier" size="md" borderRadius="full" variant="solid" colorScheme="purple" mr={2} mb={2}>
          <TagLabel>Multiplier: {localFilters.multiplier}x+</TagLabel>
          <TagCloseButton onClick={() => setLocalFilters(prev => ({ ...prev, multiplier: '' }))} />
        </Tag>
      );
    }

    if (localFilters.marketCap) {
      tags.push(
        <Tag key="marketCap" size="md" borderRadius="full" variant="solid" colorScheme="orange" mr={2} mb={2}>
          <TagLabel>Market Cap: {localFilters.marketCap}</TagLabel>
          <TagCloseButton onClick={() => setLocalFilters(prev => ({ ...prev, marketCap: '' }))} />
        </Tag>
      );
    }

    if (localFilters.industry) {
      tags.push(
        <Tag key="industry" size="md" borderRadius="full" variant="solid" colorScheme="teal" mr={2} mb={2}>
          <TagLabel>Industry: {localFilters.industry}</TagLabel>
          <TagCloseButton onClick={() => setLocalFilters(prev => ({ ...prev, industry: '' }))} />
        </Tag>
      );
    }

    return tags;
  };

  const filteredAlertsCount = useMemo(() => filteredAlerts.length, [filteredAlerts]);

  const toggleAlertExpansion = (id: string) => {
    setExpandedAlertId(prevId => prevId === id ? null : id);
  };

  return (
    <Box minHeight="100vh" bg={bgColor}>
      <Navbar />
      <Container maxW="container.xl" py={6}>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Flex gap={2}>
            <Input
              placeholder="Search ticker"
              value={filterTicker}
              onChange={(e) => setFilterTicker(e.target.value)}
              size="sm"
              width="150px"
              bg={componentBgColor}
              borderRadius="md"
              borderColor={borderColor}
              color={textColor}
              _placeholder={{ color: subtleTextColor }}
            />
            <Button 
              leftIcon={<FiFilter />} 
              onClick={onOpen}
              size="sm"
              bg={accentColor}
              color="white"
              _hover={{ bg: `${accentColor}CC` }}
              borderRadius="md"
            >
              Filters
            </Button>
          </Flex>
        </Flex>

        <Flex flexWrap="wrap" mb={4} alignItems="center">
          {renderFilterTags()}
          {(filterTicker || Object.values(localFilters).some(Boolean) || dateRange) && (
            <Tooltip label="Clear all filters">
              <IconButton
                aria-label="Clear all filters"
                icon={<FiTrash2 />}
                size="sm"
                variant="ghost"
                onClick={clearAllFilters}
                ml={2}
                color={textColor}
              />
            </Tooltip>
          )}
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "xl" }}>
          <ModalOverlay />
          <ModalContent bg={componentBgColor} borderRadius="md">
            <ModalHeader color={headingColor}>Filter Alerts</ModalHeader>
            <ModalCloseButton color={textColor} />
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text mb={2} color={textColor}>Date</Text>
                  <Popover placement="bottom-start">
                    <PopoverTrigger>
                      <Button leftIcon={<FiCalendar />} rightIcon={<FiChevronDown />} size="md" bg={componentBgColor} color={textColor} borderColor={borderColor} borderWidth={1} width="100%" borderRadius="md">
                        {selectedDate ? format(selectedDate, 'MMM d, yyyy') : 'Select Date'}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent width="auto" borderColor={borderColor} borderRadius="md">
                      <PopoverBody p={3} bg={componentBgColor}>
                        <Box className="calendar-container" sx={calendarStyles}>
                          <Flex justifyContent="space-between" alignItems="center" mb={3}>
                            <IconButton
                              aria-label="Previous month"
                              icon={<FiChevronLeft />}
                              onClick={handlePrevMonth}
                              size="sm"
                              variant="ghost"
                              color={accentColor}
                              _hover={{ bg: borderColor }}
                            />
                            <Text fontWeight="bold" color={textColor} fontSize="0.9rem">{format(currentMonth, 'MMMM yyyy')}</Text>
                            <IconButton
                              aria-label="Next month"
                              icon={<FiChevronRight />}
                              onClick={() => setCurrentMonth(new Date())} // Reset to current month
                              size="sm"
                              variant="ghost"
                              color={accentColor}
                              isDisabled={isCurrentMonthToday}
                              _hover={{ bg: borderColor }}
                            />
                          </Flex>
                          <Grid templateColumns="repeat(7, 1fr)" gap={1} textAlign="center" mb={2}>
                            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                              <Box key={day} fontWeight="bold" color={textColor} fontSize="0.8rem">{day}</Box>
                            ))}
                          </Grid>
                          {renderCalendar()}
                          <Flex justifyContent="space-between" mt={3}>
                            <Button 
                              size="sm" 
                              onClick={() => setSelectedDate(null)} 
                              variant="outline" 
                              borderColor={borderColor}
                              _hover={{ bg: borderColor }}
                            >
                              Clear
                            </Button>
                            <Button 
                              size="sm" 
                              bg={accentColor} 
                              color="white" 
                              onClick={handleApplyDateRange} 
                              _hover={{ bg: `${accentColor}CC` }}
                              borderRadius="md"
                            >
                              Apply
                            </Button>
                          </Flex>
                        </Box>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>

                <Box>
                  <Text mb={2} color={textColor}>Interval</Text>
                  <Select
                    value={filterInterval}
                    onChange={(e) => setFilterInterval(e.target.value)}
                    bg={componentBgColor}
                    color={textColor}
                    borderColor={borderColor}
                    borderRadius="md"
                  >
                    <option value="">All Intervals</option>
                    <option value="30 Days">30 Days</option>
                    <option value="60 Days">60 Days</option>
                    <option value="90 Days">90 Days</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Yearly">Yearly</option>
                  </Select>
                </Box>

                <Box>
                  <Text mb={2} color={textColor}>Multiplier</Text>
                  <Select
                    value={filterMultiplier}
                    onChange={(e) => setFilterMultiplier(e.target.value)}
                    bg={componentBgColor}
                    color={textColor}
                    borderColor={borderColor}
                    borderRadius="md"
                  >
                    <option value="">All Multipliers</option>
                    <option value="2">2x</option>
                    <option value="3">3x</option>
                    <option value="4">4x</option>
                    <option value="5">5x</option>
                    <option value="10">10x+</option>
                  </Select>
                </Box>

                <Box>
                  <Text mb={2} color={textColor}>Market Cap</Text>
                  <Select
                    value={filterMarketCap}
                    onChange={(e) => setFilterMarketCap(e.target.value)}
                    bg={componentBgColor}
                    color={textColor}
                    borderColor={borderColor}
                    borderRadius="md"
                  >
                    <option value="">All Market Caps</option>
                    <option value="nano">Nano Cap (&lt;$50M)</option>
                    <option value="micro">Micro Cap ($50M-$300M)</option>
                    <option value="small">Small Cap ($300M-$2B)</option>
                    <option value="mid">Mid Cap ($2B-$10B)</option>
                    <option value="large">Large Cap ($10B-$200B)</option>
                    <option value="mega">Mega Cap (&gt;$200B)</option>
                  </Select>
                </Box>

                <Box>
                  <Text mb={2} color={textColor}>Industry</Text>
                  <Select
                    value={filterIndustry}
                    onChange={(e) => setFilterIndustry(e.target.value)}
                    bg={componentBgColor}
                    color={textColor}
                    borderColor={borderColor}
                    borderRadius="md"
                  >
                    <option value="">All Industries</option>
                    <option value="Technology">Technology</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Financial Services">Financial Services</option>
                    <option value="Consumer Cyclical">Consumer Cyclical</option>
                    <option value="Industrials">Industrials</option>
                    <option value="Communication Services">Communication Services</option>
                    <option value="Consumer Defensive">Consumer Defensive</option>
                    <option value="Energy">Energy</option>
                    <option value="Basic Materials">Basic Materials</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Utilities">Utilities</option>
                  </Select>
                </Box>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} color={textColor}>
                Cancel
              </Button>
              <Button bg={accentColor} color="white" onClick={handleApplyFilters} _hover={{ bg: `${accentColor}CC` }} borderRadius="md">
                Apply Filters
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {isApplyingFilters ? (
          <Flex justify="center" align="center" height="200px">
            <Spinner size="xl" color={accentColor} />
          </Flex>
        ) : filteredAlerts.length > 0 ? (
          <VStack spacing={4} align="stretch">
            {filteredAlerts.map((alert) => (
              <CompactAlertCard
                key={alert.id}
                alert={alert}
                onClick={() => toggleAlertExpansion(alert.id)}
                isExpandable={true}
                isExpanded={expandedAlertId === alert.id}
                isActive={false}
              />
            ))}
          </VStack>
        ) : (
          <Text textAlign="center" color={subtleTextColor} mt={4}>
            No alerts match the current filters.
          </Text>
        )}

        {hasNextPage && (
          <Button 
            onClick={() => fetchNextPage()} 
            isLoading={isFetchingNextPage} 
            mt={4}
            bg={accentColor}
            color="white"
            _hover={{ bg: `${accentColor}CC` }}
            borderRadius="md"
          >
            Load More
          </Button>
        )}

        {error && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}

        <Box ref={ref} />
      </Container>
    </Box>
  );
};

export default Alerts;