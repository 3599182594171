import React, { useState } from 'react';
import {
  Box, Text, useColorModeValue, Flex, Heading, Button, VStack, FormControl,
  FormLabel, Input, useToast, Divider, Container, InputGroup, InputRightElement,
  IconButton, Link
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../config/firebase';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const bgGradient = useColorModeValue(
    'linear(to-br, gray.50, #e6f3ff)',
    'linear(to-br, black, gray.900)'
  );
  
  const boxBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const inputBg = useColorModeValue('white', 'gray.700');
  const inputColor = useColorModeValue('gray.800', 'white');
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');
  const accentColor = '#1a88e4';

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to log in. Please check your credentials.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/dashboard');
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to sign in with Google.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex 
      direction="column" 
      minHeight="100vh" 
      bgGradient={bgGradient}
    >
      <Container maxW="container.xl" py={12}>
        <Box maxW="md" mx="auto">
          <VStack spacing={6}>
            <Heading as="h1" size="xl" textAlign="center">Log In</Heading>
            <form onSubmit={handleLogin} style={{ width: '100%' }}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    bg={inputBg}
                    color={inputColor}
                    _placeholder={{ color: placeholderColor }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      bg={inputBg}
                      color={inputColor}
                      _placeholder={{ color: placeholderColor }}
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label={showPassword ? "Hide password" : "Show password"}
                        icon={showPassword ? <FiEyeOff /> : <FiEye />}
                        onClick={() => setShowPassword(!showPassword)}
                        variant="ghost"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button
                  type="submit"
                  colorScheme="blue"
                  width="100%"
                  isLoading={isLoading}
                  loadingText="Logging In"
                  bg={accentColor}
                  _hover={{ bg: `${accentColor}CC` }}
                >
                  Log In
                </Button>
              </VStack>
            </form>
            <Flex align="center" width="100%" my={2}>
              <Divider flex="1" />
              <Text px={3} color={useColorModeValue('gray.600', 'gray.400')}>or</Text>
              <Divider flex="1" />
            </Flex>
            <Button leftIcon={<FcGoogle />} onClick={handleGoogleSignIn} width="100%" variant="outline">
              Sign in with Google
            </Button>
            <Text>
              Don't have an account?{' '}
              <Link as={RouterLink} to="/signup" color={accentColor}>
                Sign up
              </Link>
            </Text>
          </VStack>
        </Box>
      </Container>
    </Flex>
  );
};

export default Login;