import React, { useState, useRef, useEffect } from 'react';
import {
  FormControl,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
  Button,
  Text,
  Flex,
  useColorModeValue
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface MultiSelectFilterProps {
  name: string;
  options: string[];
  placeholder: string;
  selectedTags: string[];
  onChange: (selectedTags: string[]) => void;
  accentColor: string;
  borderRadius: string;
}

const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
  name,
  options,
  placeholder,
  selectedTags,
  onChange,
  accentColor,
  borderRadius
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const bgColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const hoverBgColor = useColorModeValue("gray.100", "whiteAlpha.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.200");
  const mutedTextColor = useColorModeValue("gray.600", "gray.400");
  const dropdownBgColor = useColorModeValue("white", "black");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOption = (option: string) => {
    if (selectedTags.includes(option)) {
      onChange(selectedTags.filter(tag => tag !== option));
    } else {
      onChange([...selectedTags, option]);
    }
    setIsOpen(false);
  };

  // Function to get the display name of the filter
  const getFilterDisplayName = (name: string) => {
    switch (name) {
      case 'filterIntervals':
        return 'Intervals';
      case 'filterMultipliers':
        return 'Multipliers';
      case 'filterMarketCaps':
        return 'Market Caps';
      case 'filterIndustries':
        return 'Industries';
      default:
        return name;
    }
  };

  return (
    <FormControl>
      <Box position="relative" ref={dropdownRef}>
        <Button
          rightIcon={<ChevronDownIcon />}
          onClick={() => setIsOpen(!isOpen)}
          width="100%"
          bg={bgColor}
          borderColor={borderColor}
          color={textColor}
          _hover={{ bg: hoverBgColor }}
          _active={{ bg: hoverBgColor }}
          borderRadius={borderRadius}
          justifyContent="space-between"
          alignItems="center"
          height="auto"
          py={2}
        >
          <Flex align="center" width="100%">
            <Text isTruncated>
              {selectedTags.length > 0 ? `${selectedTags.length} Selected` : placeholder}
            </Text>
            {selectedTags.length > 0 && (
              <Text fontSize="xs" color={mutedTextColor} ml={2}>
                ({getFilterDisplayName(name)})
              </Text>
            )}
          </Flex>
        </Button>
        {isOpen && (
          <Box
            position="absolute"
            top="100%"
            left={0}
            right={0}
            bg={dropdownBgColor}
            borderColor={borderColor}
            borderWidth={1}
            borderRadius={borderRadius}
            mt={1}
            zIndex={1}
            maxH="200px"
            overflowY="auto"
          >
            {options.filter(option => !selectedTags.includes(option)).map(option => (
              <Box
                key={option}
                px={3}
                py={2}
                cursor="pointer"
                bg={selectedTags.includes(option) ? hoverBgColor : 'transparent'}
                color={textColor}
                _hover={{ bg: hoverBgColor }}
                onClick={() => toggleOption(option)}
              >
                {option}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Wrap spacing={2} mt={2}>
        {selectedTags.map((tag: string) => (
          <WrapItem key={tag}>
            <Tag
              size="sm"
              borderRadius="full"
              variant="solid"
              bg={accentColor}
              color="white"
            >
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton
                onClick={() => onChange(selectedTags.filter((t: string) => t !== tag))}
              />
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
    </FormControl>
  );
};

export default MultiSelectFilter;