import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDw9KlH2ZKGv9k4BsQQqi1s5wBM6AbUZ_g",
  authDomain: "volume-webapp.firebaseapp.com",
  projectId: "volume-webapp",
  storageBucket: "volume-webapp.appspot.com",
  messagingSenderId: "134393438481",
  appId: "1:134393438481:web:36ebc7aa85c2874517ecb0",
  measurementId: "G-HVSE22E2JW"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);