import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { User } from 'firebase/auth';
import { auth } from './config/firebase';
import theme from './theme';
import { GlobalFiltersProvider } from './contexts/GlobalFiltersContext';
import { AlertsProvider } from './contexts/AlertsContext';
import { QueryClient, QueryClientProvider } from 'react-query';

// Import your components
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import HowTo from './components/HowTo';
import Alerts from './components/Alerts';

// Create a client
const queryClient = new QueryClient();

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <GlobalFiltersProvider>
          <AlertsProvider>
            <Router>
              <Routes>
                <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
                <Route path="/signup" element={user ? <Navigate to="/dashboard" /> : <SignUp />} />
                <Route
                  path="/dashboard"
                  element={user ? <Dashboard /> : <Navigate to="/login" />}
                />
                <Route
                  path="/settings"
                  element={user ? <Settings /> : <Navigate to="/login" />}
                />
                <Route
                  path="/how-to"
                  element={user ? <HowTo /> : <Navigate to="/login" />}
                />
                <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
                <Route
                  path="/alerts"
                  element={user ? <Alerts /> : <Navigate to="/login" />}
                />
              </Routes>
            </Router>
          </AlertsProvider>
        </GlobalFiltersProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

export default App;