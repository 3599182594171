import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';

interface GlobalFilters {
  filterIntervals: string[];
  filterMultipliers: string[];
  filterMarketCaps: string[];
  filterIndustries: string[];
}

interface GlobalFiltersContextType {
  globalFilters: GlobalFilters;
  setGlobalFilters: React.Dispatch<React.SetStateAction<GlobalFilters>>;
}

const GlobalFiltersContext = createContext<GlobalFiltersContextType | undefined>(undefined);

export const useGlobalFilters = () => {
  const context = useContext(GlobalFiltersContext);
  if (!context) {
    throw new Error('useGlobalFilters must be used within a GlobalFiltersProvider');
  }
  return context;
};

export const GlobalFiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [globalFilters, setGlobalFilters] = useState<GlobalFilters>({
    filterIntervals: [],
    filterMultipliers: [],
    filterMarketCaps: [],
    filterIndustries: [],
  });

  useEffect(() => {
    const fetchGlobalFilters = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          const newFilters = {
            filterIntervals: data.filterIntervals || [],
            filterMultipliers: data.filterMultipliers || [],
            filterMarketCaps: data.filterMarketCaps || [],
            filterIndustries: data.filterIndustries || [],
          };
          console.log('Fetched global filters:', newFilters);
          setGlobalFilters(newFilters);
        }
      }
    };
    fetchGlobalFilters();
  }, []);

  console.log('Current global filters:', globalFilters);

  return (
    <GlobalFiltersContext.Provider value={{ globalFilters, setGlobalFilters }}>
      {children}
    </GlobalFiltersContext.Provider>
  );
};