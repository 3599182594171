import React from 'react';
import {
  Box, Container, Text, VStack, Icon, Flex,
  Button, Link, List, ListItem,
  Heading, SimpleGrid, Divider, useColorModeValue
} from '@chakra-ui/react';
import { FiInfo, FiMonitor, FiSettings, FiMessageCircle, FiChevronRight } from 'react-icons/fi';
import Navbar from './Navbar';

interface SectionProps {
  title: string;
  icon: React.ElementType;
  children: React.ReactNode;
  bgColor: string;
  borderColor: string;
  textColor: string;
}

const Section: React.FC<SectionProps> = ({ title, icon, children, bgColor, borderColor, textColor }) => {
  return (
    <Box
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      p={6}
      boxShadow="md"
    >
      <Flex align="center" mb={2}>
        <Icon as={icon} boxSize={6} color="#1a88e4" mr={3} />
        <Heading size="md" color={textColor}>{title}</Heading>
      </Flex>
      <Divider mb={4} borderColor={borderColor} />
      <VStack align="start" spacing={4}>
        {children}
      </VStack>
    </Box>
  );
};

const FeatureList: React.FC<{ items: string[] }> = ({ items }) => {
  const textColor = useColorModeValue("gray.800", "gray.300");

  return (
    <List spacing={2}>
      {items.map((item, index) => (
        <ListItem key={index} display="flex" alignItems="center">
          <Icon as={FiChevronRight} color="#1a88e4" mr={2} />
          <Text color={textColor}>{item}</Text>
        </ListItem>
      ))}
    </List>
  );
};

const HowTo: React.FC = () => {
  const accentColor = '#1a88e4';

  const bgColor = useColorModeValue("gray.50", "black");
  const cardBg = useColorModeValue("white", "whiteAlpha.50");
  const borderColor = useColorModeValue("gray.200", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.300");
  const headingColor = useColorModeValue("gray.900", "gray.100");
  const subTextColor = useColorModeValue("gray.600", "gray.400");

  return (
    <Box minHeight="100vh" bg={bgColor}>
      <Navbar />
      <Container maxW="container.xl" py={6}>
        <VStack spacing={4} align="stretch">
          <Heading size="xl" textAlign="center" color={headingColor} mb={1}>
            How to Use Volume Scanner
          </Heading>
          <Text fontSize="md" textAlign="center" color={subTextColor} mb={4}>
            Maximize your trading potential with our powerful volume analysis tool
          </Text>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <Section title="Overview" icon={FiInfo} bgColor={cardBg} borderColor={borderColor} textColor={textColor}>
              <Text color={textColor}>
                Volume Scanner detects high volume moves across multiple timeframes, scanning over 7,700 stocks daily after market close at 7 PM EST.
              </Text>
              <FeatureList items={[
                "Scans 7,700+ stocks daily",
                "Detects volume abnormalities",
                "Customizable filtering options",
                "Daily updates at 7 PM EST"
              ]} />
            </Section>

            <Section title="Dashboard & Alerts" icon={FiMonitor} bgColor={cardBg} borderColor={borderColor} textColor={textColor}>
              <Text color={textColor}>
                Your dashboard shows recent scan results, while the alerts section lists all previous events for up to 30 days.
              </Text>
              <FeatureList items={[
                "Real-time dashboard updates",
                "30 days of historical alerts",
                "Detailed view of each alert",
                "Easy-to-use interface"
              ]} />
            </Section>

            <Section title="Custom Settings" icon={FiSettings} bgColor={cardBg} borderColor={borderColor} textColor={textColor}>
              <Text color={textColor}>
                Tailor your dashboard to show only the alerts you're most interested in by customizing your filters:
              </Text>
              <FeatureList items={[
                "Filter by timeframes",
                "Adjust volume multipliers",
                "Select market capitalizations",
                "Focus on specific industries"
              ]} />
            </Section>

            <Section title="Discord Support" icon={FiMessageCircle} bgColor={cardBg} borderColor={borderColor} textColor={textColor}>
              <Text color={textColor}>
                Join our vibrant Discord community for:
              </Text>
              <FeatureList items={[
                "Additional support",
                "Networking with traders",
                "Idea sharing and strategies",
                "Latest updates and news"
              ]} />
              <Button 
                mt={4} 
                bg={accentColor}
                color="white"
                _hover={{ bg: `${accentColor}CC` }}
                leftIcon={<Icon as={FiMessageCircle} />}
                as={Link}
                href="https://discord.gg/volumescanner"
                isExternal
              >
                Join Our Discord
              </Button>
            </Section>
          </SimpleGrid>

          <Divider my={8} borderColor={borderColor} />

          <Box 
            p={6} 
            bg={cardBg}
            borderRadius="lg"
            borderWidth="1px"
            borderColor={borderColor}
            boxShadow="md"
            textAlign="center"
          >
            <Heading size="lg" mb={4} color={headingColor}>
              Ready to start?
            </Heading>
            <Text fontSize="lg" mb={6} color={textColor}>
              Head to your dashboard and start discovering potential trading opportunities today!
            </Text>
            <Button
              as={Link}
              href="/dashboard"
              size="lg"
              bg={accentColor}
              color="white"
              _hover={{ bg: `${accentColor}CC` }}
              fontWeight="normal"
            >
              Go to Dashboard
            </Button>
          </Box>

          <Text fontSize="sm" color={subTextColor} textAlign="center" mt={4}>
            This product is currently in beta. Please report any issues in our official Discord server.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default HowTo;