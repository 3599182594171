import React from 'react';
import { Box, SimpleGrid, Stat, StatLabel, StatNumber, useColorModeValue } from '@chakra-ui/react';

interface DashboardSummaryProps {
  totalAlerts: number;
  filteredAlerts: number;
}

export const DashboardSummary: React.FC<DashboardSummaryProps> = ({ totalAlerts, filteredAlerts }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Box p={4} bg={bgColor} borderRadius="md" borderWidth={1} borderColor={borderColor}>
      <SimpleGrid columns={2} spacing={4}>
        <Stat>
          <StatLabel color={textColor}>Total Alerts</StatLabel>
          <StatNumber>{totalAlerts}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel color={textColor}>Filtered Alerts</StatLabel>
          <StatNumber>{filteredAlerts}</StatNumber>
        </Stat>
      </SimpleGrid>
    </Box>
  );
};
