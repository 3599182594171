import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors: {
    brand: {
      50: '#e6fff2',
      100: '#b3ffe0',
      200: '#80ffcd',
      300: '#4dffbb',
      400: '#1affa8',
      500: '#00ff9d',
      600: '#00cc7e',
      700: '#00995e',
      800: '#00663f',
      900: '#00331f',
    },
    accent: {
      50: '#ffe6e6',
      100: '#ffb3b3',
      200: '#ff8080',
      300: '#ff4d4d',
      400: '#ff1a1a',
      500: '#ff0000',
      600: '#cc0000',
      700: '#990000',
      800: '#660000',
      900: '#330000',
    },
  },
  fonts: {
    heading: '"Inter", sans-serif',
    body: '"Inter", sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'semibold',
        borderRadius: 'md',
      },
      variants: {
        solid: (props: StyleFunctionProps) => ({
          bg: props.colorMode === 'dark' ? 'gray.800' : 'brand.600',
          color: props.colorMode === 'dark' ? 'white' : 'black',
          _hover: {
            bg: props.colorMode === 'dark' ? 'gray.700' : 'brand.700',
          },
        }),
        outline: (props: StyleFunctionProps) => ({
          borderColor: props.colorMode === 'dark' ? 'gray.600' : 'brand.600',
          color: props.colorMode === 'dark' ? 'white' : 'brand.600',
          _hover: {
            bg: props.colorMode === 'dark' ? 'gray.700' : 'brand.600',
            color: props.colorMode === 'dark' ? 'white' : 'black',
          },
        }),
      },
    },
    Card: {
      baseStyle: (props: StyleFunctionProps) => ({
        bg: props.colorMode === 'dark' ? 'gray.800' : 'white',
        borderRadius: 'lg',
        boxShadow: 'md',
      }),
    },
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'black' : 'gray.50',
        color: props.colorMode === 'dark' ? 'white' : 'gray.900',
      },
    }),
  },
});

export default theme;