import React, { createContext, useContext, useState } from 'react';

type DateRange = {
  from: Date;
  to?: Date;
};

export type AlertsContextType = {
  dateRange: DateRange | undefined;
  setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  filterTicker: string;
  setFilterTicker: React.Dispatch<React.SetStateAction<string>>;
  filterInterval: string;
  setFilterInterval: React.Dispatch<React.SetStateAction<string>>;
  filterMultiplier: string;
  setFilterMultiplier: React.Dispatch<React.SetStateAction<string>>;
  filterMarketCap: string;
  setFilterMarketCap: React.Dispatch<React.SetStateAction<string>>;
  filterIndustry: string;
  setFilterIndustry: React.Dispatch<React.SetStateAction<string>>;
};

const AlertsContext = createContext<AlertsContextType | undefined>(undefined);

export const AlertsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [filterTicker, setFilterTicker] = useState('');
  const [filterInterval, setFilterInterval] = useState('');
  const [filterMultiplier, setFilterMultiplier] = useState('');
  const [filterMarketCap, setFilterMarketCap] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');

  return (
    <AlertsContext.Provider
      value={{
        dateRange,
        setDateRange,
        filterTicker,
        setFilterTicker,
        filterInterval,
        setFilterInterval,
        filterMultiplier,
        setFilterMultiplier,
        filterMarketCap,
        setFilterMarketCap,
        filterIndustry,
        setFilterIndustry,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export const useAlertsContext = () => {
  const context = useContext(AlertsContext);
  if (context === undefined) {
    throw new Error('useAlertsContext must be used within an AlertsProvider');
  }
  return context;
};