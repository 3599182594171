import React, { useState, useEffect } from 'react';
import {
  Box, Flex, VStack, Heading, FormControl, FormLabel, Input, Button, useToast,
  Divider, Icon, Alert, AlertIcon, useBreakpointValue, Container,
  useColorMode, useColorModeValue, HStack, Text
} from '@chakra-ui/react';
import { FiUser, FiFilter, FiBell, FiSave } from 'react-icons/fi';
import { auth, db } from '../config/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import Navbar from './Navbar';
import { useGlobalFilters } from '../contexts/GlobalFiltersContext';
import MultiSelectFilter from './MultiSelectFilter';

interface UserSettings {
  displayName: string;
  email: string;
  filterIntervals: string[];
  filterMultipliers: string[];
  filterMarketCaps: string[];
  filterIndustries: string[];
}

const Settings: React.FC = () => {
  const [settings, setSettings] = useState<UserSettings>({
    displayName: '',
    email: '',
    filterIntervals: [],
    filterMultipliers: [],
    filterMarketCaps: [],
    filterIndustries: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState('account');
  const toast = useToast();
  const { globalFilters, setGlobalFilters } = useGlobalFilters();

  const { colorMode } = useColorMode();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const bgColor = useColorModeValue("gray.50", "black");
  const boxBg = useColorModeValue("white", "whiteAlpha.50");
  const borderColor = useColorModeValue("gray.200", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.300");
  const mutedTextColor = useColorModeValue("gray.600", "gray.500");
  const activeBg = useColorModeValue("gray.100", "whiteAlpha.200");
  const accentColor = '#1a88e4';
  const readOnlyInputBg = useColorModeValue("gray.100", "whiteAlpha.100");
  const borderRadius = "md";

  const filterOptions = {
    filterIntervals: {
      options: ['30 Days', '60 Days', '90 Days', 'Quarterly', 'Yearly'],
      placeholder: 'Select intervals...'
    },
    filterMultipliers: {
      options: ['2', '3', '4', '5', '10'],
      placeholder: 'Select multipliers...'
    },
    filterMarketCaps: {
      options: ['Micro (<300M)', 'Small (300M-2B)', 'Mid (2B-10B)', 'Large (10B-200B)', 'Mega (>200B)'],
      placeholder: 'Select market caps...'
    },
    filterIndustries: {
      options: ['Technology', 'Healthcare', 'Financials', 'Consumer Discretionary', 'Industrials'],
      placeholder: 'Select industries...'
    }
  };

  useEffect(() => {
    const fetchSettings = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
          if (userDoc.exists()) {
            setSettings({
              displayName: auth.currentUser.displayName || '',
              email: auth.currentUser.email || '',
              filterIntervals: userDoc.data().filterIntervals || [],
              filterMultipliers: userDoc.data().filterMultipliers || [],
              filterMarketCaps: userDoc.data().filterMarketCaps || [],
              filterIndustries: userDoc.data().filterIndustries || [],
            });
          }
        } catch (error) {
          console.error("Error fetching user settings:", error);
          toast({
            title: 'Error loading settings.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    };
    fetchSettings();
  }, [toast]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleTagChange = (name: string) => (selectedTags: string[]) => {
    setSettings(prev => ({
      ...prev,
      [name]: selectedTags,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (auth.currentUser) {
        await updateProfile(auth.currentUser, { displayName: settings.displayName });
        await setDoc(doc(db, 'users', auth.currentUser.uid), {
          filterIntervals: settings.filterIntervals,
          filterMultipliers: settings.filterMultipliers,
          filterMarketCaps: settings.filterMarketCaps,
          filterIndustries: settings.filterIndustries,
        }, { merge: true });
        setGlobalFilters({
          filterIntervals: settings.filterIntervals,
          filterMultipliers: settings.filterMultipliers,
          filterMarketCaps: settings.filterMarketCaps,
          filterIndustries: settings.filterIndustries,
        });
        toast({
          title: 'Settings updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error updating settings.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const renderSettingsContent = () => {
    switch (activeSection) {
      case 'account':
        return (
          <VStack spacing={6} align="stretch">
            <FormControl>
              <FormLabel color={textColor}>Display Name</FormLabel>
              <Input
                name="displayName"
                value={settings.displayName}
                onChange={handleChange}
                bg={readOnlyInputBg}
                borderColor={borderColor}
                color={textColor}
                borderRadius={borderRadius}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={textColor}>Email</FormLabel>
              <Input
                name="email"
                value={settings.email}
                isReadOnly
                bg={readOnlyInputBg}
                borderColor={borderColor}
                color={textColor}
                borderRadius={borderRadius}
              />
            </FormControl>
          </VStack>
        );
      case 'notifications':
        return (
          <Alert status="info" borderRadius="md" bg={boxBg} color={textColor}>
            <AlertIcon />
            This feature is coming in the near future. For now, please use our official Discord to receive notifications and updates on scans/alerts.
          </Alert>
        );
      case 'filters':
        return (
          <VStack spacing={6} align="stretch">
            {Object.entries(filterOptions).map(([key, { options, placeholder }]) => (
              <MultiSelectFilter
                key={key}
                name={key}
                options={options}
                placeholder={placeholder}
                selectedTags={settings[key as keyof UserSettings] as string[]}
                onChange={handleTagChange(key)}
                accentColor={accentColor}
                borderRadius={borderRadius}
              />
            ))}
          </VStack>
        );
      default:
        return null;
    }
  };

  const renderNavigationButtons = () => {
    const buttons = [
      { name: 'account', icon: FiUser, label: 'Account' },
      { name: 'notifications', icon: FiBell, label: 'Notifications' },
      { name: 'filters', icon: FiFilter, label: 'Filters' },
    ];

    return buttons.map((button) => (
      <Button
        key={button.name}
        onClick={() => setActiveSection(button.name)}
        justifyContent={isMobile ? "center" : "flex-start"}
        fontWeight="medium"
        bg={activeSection === button.name ? activeBg : 'transparent'}
        color={activeSection === button.name ? accentColor : textColor}
        _hover={{ bg: activeBg }}
        leftIcon={
          <Icon
            as={button.icon}
            boxSize={5}
          />
        }
        flex={isMobile ? 1 : "unset"}
        width={isMobile ? "auto" : "full"}
        borderRadius={borderRadius}
        size={isMobile ? "sm" : "md"}
      >
        {isMobile ? '' : button.label}
      </Button>
    ));
  };

  return (
    <Box minH="100vh" bg={bgColor}>
      <Navbar />
      <Container maxW="container.xl" py={6}>
        <Flex direction={isMobile ? "column" : "row"} gap={6}>
          <Box w={isMobile ? "full" : "250px"} mb={isMobile ? 6 : 0}>
            {isMobile ? (
              <HStack spacing={2} justify="space-between">
                {renderNavigationButtons()}
              </HStack>
            ) : (
              <VStack spacing={2} align="stretch">
                {renderNavigationButtons()}
              </VStack>
            )}
          </Box>
          <Box flex={1}>
            <Box bg={boxBg} borderRadius={borderRadius} boxShadow="md" p={6} borderColor={borderColor} borderWidth={1}>
              <Heading size="lg" mb={6} color={textColor}>
                {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)} Settings
              </Heading>
              <form onSubmit={handleSubmit}>
                {renderSettingsContent()}
                <Divider my={6} borderColor={borderColor} />
                <Button 
                  type="submit" 
                  bg={accentColor} 
                  color="white" 
                  isLoading={isLoading} 
                  _hover={{ opacity: 0.8 }}
                  isDisabled={activeSection === 'notifications'}
                  opacity={activeSection === 'notifications' ? 0.5 : 1}
                  cursor={activeSection === 'notifications' ? 'not-allowed' : 'pointer'}
                  w={isMobile ? 'full' : 'auto'}
                  leftIcon={<FiSave />}
                  borderRadius={borderRadius}
                >
                  Save Changes
                </Button>
              </form>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Settings;