import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box, Flex, VStack, Text, Button, useColorModeValue,
  Input, IconButton, Spinner, Container, Heading,
  useDisclosure, Drawer, DrawerBody, DrawerHeader,
  DrawerOverlay, DrawerContent, DrawerCloseButton,
  InputGroup, InputRightElement
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FiSearch, FiFilter } from 'react-icons/fi';
import { collection, query, orderBy, limit, startAfter, getDocs, where } from 'firebase/firestore';
import InfiniteScroll from 'react-infinite-scroll-component';
import Navbar from './Navbar';
import { db } from '../config/firebase';
import { CompactAlertCard } from './CompactAlertCard';
import { useGlobalFilters } from '../contexts/GlobalFiltersContext';
import { Alert } from '../types/alert';
import { DashboardSummary } from './DashboardSummary';

const ALERTS_PER_PAGE = 20;

const Dashboard: React.FC = () => {
  const [filterTicker, setFilterTicker] = useState('');
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedAlertId, setExpandedAlertId] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue("gray.50", "black");
  const cardBg = useColorModeValue("white", "whiteAlpha.50");
  const borderColor = useColorModeValue("gray.200", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.300");
  const inputBg = useColorModeValue("white", "whiteAlpha.50");
  const inputColor = useColorModeValue("gray.800", "white");
  const placeholderColor = useColorModeValue("gray.400", "gray.500");
  const accentColor = '#1a88e4';

  const { globalFilters } = useGlobalFilters();

  const filteredAlerts = useMemo(() => {
    return alerts.filter(alert => {
      const matchesTicker = filterTicker === '' || alert.stockTicker.toLowerCase().includes(filterTicker.toLowerCase());
      const matchesInterval = globalFilters.filterIntervals.length === 0 || globalFilters.filterIntervals.includes(alert.interval);
      const matchesMultiplier = globalFilters.filterMultipliers.length === 0 || 
        globalFilters.filterMultipliers.some(mult => {
          const multiplier = parseFloat(mult);
          return isNaN(multiplier) ? false : alert.volumeMultiple >= multiplier;
        });
      return matchesTicker && matchesInterval && matchesMultiplier;
    });
  }, [alerts, filterTicker, globalFilters]);

  useEffect(() => {
    fetchAlerts();
  }, []);

  const fetchAlerts = async () => {
    setIsLoading(true);
    try {
      const alertsRef = collection(db, 'alerts');
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const q = query(
        alertsRef,
        where('timestamp', '>=', today),
        orderBy('timestamp', 'desc'),
        limit(ALERTS_PER_PAGE)
      );

      const querySnapshot = await getDocs(q);
      const fetchedAlerts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Alert[];

      setAlerts(fetchedAlerts);
    } catch (error) {
      console.error("Error fetching alerts:", error);
      setError("Failed to load alerts. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMoreAlerts = useCallback(() => {
    if (!hasMore) return;

    const lastAlert = alerts[alerts.length - 1];
    const alertsRef = collection(db, 'alerts');
    const q = query(
      alertsRef,
      where('timestamp', '>=', new Date(new Date().setHours(0, 0, 0, 0))),
      orderBy('timestamp', 'desc'),
      startAfter(lastAlert.timestamp),
      limit(ALERTS_PER_PAGE)
    );

    getDocs(q).then((snapshot) => {
      const newAlerts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Alert));
      if (newAlerts.length < ALERTS_PER_PAGE) {
        setHasMore(false);
      }
      setAlerts(prevAlerts => [...prevAlerts, ...newAlerts]);
    });
  }, [alerts, hasMore]);

  const toggleAlertExpansion = (id: string) => {
    setExpandedAlertId(prevId => prevId === id ? null : id);
  };

  return (
    <Box minHeight="100vh" bg={bgColor}>
      <Navbar />
      <Container maxW="container.xl" py={6}>
        <VStack spacing={6} align="stretch">
          <DashboardSummary
            totalAlerts={alerts.length}
            filteredAlerts={filteredAlerts.length}
          />
          
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="lg" color={textColor}>Today's Alerts</Heading>
            <Button
              leftIcon={<FiFilter />}
              onClick={onOpen}
              bg={accentColor}
              color="white"
              _hover={{ bg: `${accentColor}CC` }}
              borderRadius="md"
            >
              Filters
            </Button>
          </Flex>

          <InputGroup size="md">
            <Input
              placeholder="Search by ticker"
              value={filterTicker}
              onChange={(e) => setFilterTicker(e.target.value)}
              bg={inputBg}
              color={inputColor}
              borderColor={borderColor}
              _placeholder={{ color: placeholderColor }}
              borderRadius="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<FiSearch />}
                size="sm"
                colorScheme="blue"
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>

          {isLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" color={accentColor} />
            </Flex>
          ) : error ? (
            <Box textAlign="center" color="red.500" mt={4}>
              {error}
            </Box>
          ) : filteredAlerts.length > 0 ? (
            <InfiniteScroll
              dataLength={filteredAlerts.length}
              next={fetchMoreAlerts}
              hasMore={hasMore}
              loader={<Spinner size="md" color={accentColor} />}
              endMessage={
                <Text textAlign="center" color={textColor} mt={4}>
                  No more alerts to load.
                </Text>
              }
            >
              <VStack spacing={4} align="stretch">
                {filteredAlerts.map((alert) => (
                  <CompactAlertCard
                    key={alert.id}
                    alert={alert}
                    onClick={() => toggleAlertExpansion(alert.id)}
                    isExpandable={true}
                    isExpanded={expandedAlertId === alert.id}
                    isActive={false}
                  />
                ))}
              </VStack>
            </InfiniteScroll>
          ) : (
            <Text textAlign="center" color={textColor}>
              No alerts match your current filters.
            </Text>
          )}
        </VStack>
      </Container>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg={cardBg}>
          <DrawerCloseButton />
          <DrawerHeader color={textColor}>Filter Alerts</DrawerHeader>
          <DrawerBody>
            {/* Add filter options here */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Dashboard;
