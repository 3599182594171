import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { 
  Box, Flex, Image, HStack, Button, Menu, 
  MenuButton, MenuList, MenuItem, IconButton, useColorMode, useColorModeValue
} from '@chakra-ui/react';
import { FiMenu, FiLogOut, FiMoon, FiSun, FiHome, FiBell, FiSettings, FiHelpCircle } from 'react-icons/fi';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import { Container } from '@chakra-ui/react';

interface NavItemProps {
  item: {
    name: string;
    icon: React.ElementType;
    path: string;
  };
  isMobile?: boolean;
}

const Navbar: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const location = useLocation();

  const bgColor = useColorModeValue("white", "black");
  const textColor = useColorModeValue("gray.700", "gray.300");
  const accentColor = '#1a88e4';
  const hoverBgColor = useColorModeValue("gray.100", "whiteAlpha.200");
  const activeTextColor = "white";
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // Navigate to login page after sign out
      // Add navigation logic here, e.g., using react-router
    } catch (error) {
      console.error('Error signing out:', error);
      // Add error handling, e.g., showing a toast notification
    }
  };

  const navItems = [
    { name: 'Dashboard', icon: FiHome, path: '/dashboard' },
    { name: 'Alerts', icon: FiBell, path: '/alerts' },
    { name: 'Settings', icon: FiSettings, path: '/settings' },
    { name: 'How To', icon: FiHelpCircle, path: '/how-to' },
  ];

  const NavItem: React.FC<NavItemProps> = ({ item, isMobile = false }) => (
    <Button
      as={RouterLink}
      to={item.path}
      variant="ghost"
      size={isMobile ? "lg" : "sm"}
      leftIcon={<item.icon />}
      color={location.pathname === item.path ? activeTextColor : textColor}
      bg={location.pathname === item.path ? accentColor : "transparent"}
      _hover={{ color: accentColor, bg: hoverBgColor }}
      _active={{ bg: accentColor, color: activeTextColor }}
      justifyContent="flex-start"
      width={isMobile ? "full" : "auto"}
      borderRadius="md"
    >
      {item.name}
    </Button>
  );

  return (
    <Box 
      bg={bgColor} 
      px={4} 
      boxShadow="md" 
      position="sticky" 
      top={0} 
      zIndex={10}
      borderBottom="1px"
      borderColor={borderColor}
    >
      <Container maxW="container.xl">
        <Flex h={16} alignItems="center" justifyContent="space-between">
          <Image 
            src={colorMode === 'light' ? "/byscaglogolight.png" : "/byscaglogo.png"}
            alt="byScag Logo" 
            h="40px" 
            objectFit="contain"
          />
          <HStack spacing={4} alignItems="center">
            <HStack as="nav" spacing={2} display={{ base: 'none', md: 'flex' }}>
              {navItems.map((item) => (
                <NavItem key={item.name} item={item} />
              ))}
              <Button
                onClick={handleSignOut}
                variant="ghost"
                size="sm"
                leftIcon={<FiLogOut />}
                color={textColor}
                _hover={{ color: accentColor, bg: hoverBgColor }}
                _active={{ bg: accentColor, color: activeTextColor }}
                borderRadius="md"
              >
                Sign Out
              </Button>
            </HStack>
            <IconButton
              aria-label="Toggle color mode"
              icon={colorMode === 'light' ? <FiMoon /> : <FiSun />}
              onClick={toggleColorMode}
              variant="ghost"
              size="sm"
              color={textColor}
              _hover={{ color: accentColor, bg: hoverBgColor }}
              _active={{ bg: accentColor, color: activeTextColor }}
              borderRadius="md"
            />
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<FiMenu />}
                variant="ghost"
                display={{ base: 'inline-flex', md: 'none' }}
                size="sm"
                color={textColor}
                _hover={{ color: accentColor, bg: hoverBgColor }}
                _active={{ bg: accentColor, color: activeTextColor }}
                borderRadius="md"
              />
              <MenuList 
                bg={bgColor} 
                borderColor={borderColor} 
                borderRadius="md" 
                overflow="hidden"
                p={2}
              >
                {navItems.map((item) => (
                  <MenuItem key={item.name} p={0} _hover={{ bg: 'transparent' }} bg={bgColor}>
                    <NavItem item={item} isMobile={true} />
                  </MenuItem>
                ))}
                <MenuItem p={0} _hover={{ bg: 'transparent' }} bg={bgColor}>
                  <Button
                    onClick={handleSignOut}
                    variant="ghost"
                    size="lg"
                    leftIcon={<FiLogOut />}
                    color={textColor}
                    bg="transparent"
                    _hover={{ color: accentColor, bg: hoverBgColor }}
                    _active={{ bg: accentColor, color: activeTextColor }}
                    justifyContent="flex-start"
                    width="full"
                    borderRadius="md"
                  >
                    Sign Out
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Navbar;