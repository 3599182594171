import React, { useState } from 'react';
import {
  Box, useColorModeValue, VStack, Heading, FormControl, Input,
  Button, useToast, Container, InputGroup, InputRightElement,
  IconButton, Link, Text, Image, HStack, Flex, Divider, FormLabel
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { FcGoogle } from 'react-icons/fc';
import { FiEye, FiEyeOff, FiMail, FiLock, FiUser } from 'react-icons/fi';

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const bgGradient = useColorModeValue(
    'linear(to-br, gray.50, #e6f3ff)',
    'linear(to-br, black, gray.900)'
  );
  const boxBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const inputBg = useColorModeValue('white', 'gray.700');
  const inputColor = useColorModeValue('gray.800', 'white');
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');
  const accentColor = '#1a88e4';

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateProfile(user, { displayName });

      await setDoc(doc(db, 'users', user.uid), {
        displayName,
        email,
        receiveNotifications: true,
      });

      toast({
        title: 'Account created.',
        description: "We've created your account for you.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Error during sign up:', error);
      toast({
        title: 'An error occurred.',
        description: 'Unable to create your account. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      await setDoc(doc(db, 'users', user.uid), {
        displayName: user.displayName,
        email: user.email,
        receiveNotifications: true,
      }, { merge: true });

      toast({
        title: 'Account created.',
        description: "You've successfully signed up with Google.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Error during Google sign up:', error);
      toast({
        title: 'An error occurred.',
        description: 'Unable to sign up with Google. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex 
      direction="column" 
      minHeight="100vh" 
      bgGradient={bgGradient}
    >
      <Container maxW="container.xl" py={12}>
        <Box maxW="md" mx="auto">
          <VStack spacing={6}>
            <Heading as="h1" size="xl" textAlign="center">Sign Up</Heading>
            <form onSubmit={handleSignUp} style={{ width: '100%' }}>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Full Name</FormLabel>
                  <Input
                    type="text"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    placeholder="Enter your full name"
                    bg={inputBg}
                    color={inputColor}
                    _placeholder={{ color: placeholderColor }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    bg={inputBg}
                    color={inputColor}
                    _placeholder={{ color: placeholderColor }}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                      bg={inputBg}
                      color={inputColor}
                      _placeholder={{ color: placeholderColor }}
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label={showPassword ? "Hide password" : "Show password"}
                        icon={showPassword ? <FiEyeOff /> : <FiEye />}
                        onClick={() => setShowPassword(!showPassword)}
                        variant="ghost"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button
                  type="submit"
                  colorScheme="blue"
                  width="100%"
                  isLoading={isLoading}
                  loadingText="Signing Up"
                  bg={accentColor}
                  _hover={{ bg: `${accentColor}CC` }}
                >
                  Sign Up
                </Button>
              </VStack>
            </form>
            <Flex align="center" width="100%" my={2}>
              <Divider flex="1" />
              <Text px={3} color={useColorModeValue('gray.600', 'gray.400')}>or</Text>
              <Divider flex="1" />
            </Flex>
            <Button leftIcon={<FcGoogle />} onClick={handleGoogleSignUp} width="100%" variant="outline">
              Sign up with Google
            </Button>
            <Text>
              Already have an account?{' '}
              <Link as={RouterLink} to="/login" color={accentColor}>
                Log in
              </Link>
            </Text>
          </VStack>
        </Box>
      </Container>
    </Flex>
  );
};

export default SignUp;